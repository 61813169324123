import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';

// Create a Context
const GlobalContext = createContext();

// Provider Component
export const GlobalProvider = ({ children }) => {
  // Initiate states
  const [isMobile, setIsMobile] = useState(false);
  const [productNavItem, setProductNavItem] = useState('');
  const [showProdSecNav, setShowProdSecNav] = useState(false);

  // Check if the window width corresponds to your mobile breakpoint
  const handleCheckBp = useCallback(() => {
    const isMobileNow = window.matchMedia(`(max-width: 767px)`).matches;
    if (isMobileNow !== isMobile) {
      setIsMobile(!isMobile);
    }
  }, [isMobile]);

  // Function to set product nav item
  const handleSetProductNavItem = (id) => {
    setProductNavItem(id);
  };

  // Function to show product secondary nav
  const handleShowProdSecNav = () => {
    setShowProdSecNav(true);
  }

  // Function to hide product secondary nav
  const handleHideProdSecNav = () => {
    setShowProdSecNav(false);
  }

  // Set up event listener for resize events
  useEffect(() => {
    handleCheckBp(); // Check immediately on mount
    window.addEventListener('resize', handleCheckBp);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleCheckBp);
    };
  }, [handleCheckBp]);

  return (
    <GlobalContext.Provider value={{ isMobile, productNavItem, showProdSecNav, handleSetProductNavItem, handleShowProdSecNav, handleHideProdSecNav }}>
      {children}
    </GlobalContext.Provider>
  );
};

// Custom hook to use the global state
export const useGlobalState = () => useContext(GlobalContext);
